@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: normal;
  font-weight: 200;
  src: local("SourceSansPro-ExtraLight"),
    url("../fonts/source-sans-pro-v13-latin-200.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-200.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: italic;
  font-weight: 200;
  src: local("SourceSansPro-ExtraLightItalic"),
    url("../fonts/source-sans-pro-v13-latin-200italic.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-200italic.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: normal;
  font-weight: 300;
  src: local("SourceSansPro-Light"),
    url("../fonts/source-sans-pro-v13-latin-300.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-300.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: italic;
  font-weight: 300;
  src: local("SourceSansPro-LightItalic"),
    url("../fonts/source-sans-pro-v13-latin-300italic.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-300italic.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: normal;
  font-weight: 400;
  src: local("SourceSansPro-Regular"),
    url("../fonts/source-sans-pro-v13-latin-regular.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-regular.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: italic;
  font-weight: 400;
  src: local("SourceSansPro-Italic"),
    url("../fonts/source-sans-pro-v13-latin-italic.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-italic.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: normal;
  font-weight: 600;
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    url("../fonts/source-sans-pro-v13-latin-600.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-600.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: italic;
  font-weight: 600;
  src: local("SourceSansPro-SemiBoldItalic"),
    url("../fonts/source-sans-pro-v13-latin-600italic.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-600italic.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: normal;
  font-weight: 700;
  src: local("SourceSansPro-Bold"),
    url("../fonts/source-sans-pro-v13-latin-700.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-700.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: italic;
  font-weight: 700;
  src: local("SourceSansPro-BoldItalic"),
    url("../fonts/source-sans-pro-v13-latin-700italic.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-700italic.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: normal;
  font-weight: 900;
  src: local("SourceSansPro-Black"),
    url("../fonts/source-sans-pro-v13-latin-900.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-900.woff") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: block;
  font-style: italic;
  font-weight: 900;
  src: local("SourceSansPro-BlackItalic"),
    url("../fonts/source-sans-pro-v13-latin-900italic.woff2") format("truetype"),
    url("../fonts/source-sans-pro-v13-latin-900italic.woff") format("truetype");
}

.error-info {
  padding: 1em;
}
.no-shadow {
  box-shadow: none;
}
.tab-content {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.covid-19 {
  position: absolute;
  right: 20px;
  top: 11px;
}

.card-title {
  float: left !important;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  margin: 0 !important;
}

.content-header {
  padding: 0.5rem;
}

.content-wrapper > .content {
  padding-top: 7.5px;
}

.login-box,
.register-box {
  margin-top: -50px;
  width: 100%;
  padding: 10px;
  width: 460px;
}

@media (max-width: 576px) {
  .content-wrapper > .content {
    padding: 0;
    padding-top: 7.5px;
  }
  .login-box,
  .register-box {
    margin-top: -120px;
    width: 100%;
    padding: 10px;
  }
  .login-page,
  .register-page {
    align-items: unset;
  }
}

div.nonfooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: left;
  z-index: -100;
}

.auth-footer {
  background: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 1rem;
}

@media print {
  body * {
    visibility: hidden;
    margin: 0;
    padding: 0;
  }

  .print_area,
  .print_area * {
    visibility: visible;
  }

  .content {
    border: none;
    padding: 0 !important;
  }

  .skin-black-light .content-wrapper,
  .skin-black-light .main-footer {
    border-left: none;
  }

  .main-footer {
    display: none;
  }

  @page {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media all {
  body {
    line-height: 1 !important;
    background-color: #fff;
  }

  div.id_front {
    page-break-inside: avoid;
    page-break-after: always;
    border: 1px solid black;
    border-radius: 6px;
    width: 323px;
    height: 202px;
    position: relative;
    /*line-height: 1em;*/
    background-image: url(/assets/estudent/final3.png) !important;
  }

  .id_header {
    width: 100%;
    height: 44px;
    /*background-color: white !important;*/
    border-bottom-style: groove;
    border-color: #047098;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: center;
  }

  .header_text {
    color: #231704 !important;
    font-size: small;
    margin-top: 0px;
    font-weight: bold;
    float: center;
  }

  .astu_icon {
    float: left;
  }

  .id_admission {
    font-size: 10px;
    font-weight: bold;
    color: #231704 !important;
  }

  .id_content {
    width: 100%;
    min-height: 110px;
    padding-left: 5px;
  }

  .id_content li {
    list-style: none;
  }

  .id_text {
    width: 200px;
    float: left;
    overflow: hidden;
  }

  .label_and_data {
    float: left;
    width: 100%;
    margin-top: 2px;
    padding-right: 0px;
  }

  .id_label {
    float: left;
    width: 65px;
    margin-right: 2px;
    margin-left: 2px;
    font-size: 11px;
    font-weight: bold;
  }

  .id_data {
    float: left;
    width: 131px;
    max-height: 35px;
    font-size: 11px;
    overflow: hidden;
    color: #150101;
    padding-right: 0px;
  }

  .id_photo {
    float: right;
    padding-right: 4px;
    margin-top: 2px;
  }

  .id_no {
    float: right;
    padding-right: 0px;
    margin-top: 20px;
    margin-right: 2px;
  }

  .id_barcode {
    position: absolute;
    left: 3px;
    bottom: 1px;
  }

  .id_barcode::after {
    content: "Temporary Id";
    color: red;
    text-decoration: line-through;
    font-weight: bolder;
    font-size: 16px;
  }

  .id_qr {
    position: absolute;
    left: 8px;
    bottom: 5px;
    color: silver;
  }

  div#id_back {
    page-break-inside: avoid;
    page-break-after: always;
    border: 1px solid black;
    border-radius: 6px;
    width: 323px;
    height: 202px;
    position: relative;
    line-height: -12em;
    background-image: url(/assets/estudent/back116.png) !important;
  }

  .xx {
    padding-left: 110px;
    padding-top: 47px;
    color: #000000 !important;
    font-size: 9px;
    /*font-weight: 600;*/
  }

  .timespan {
    margin-top: 14px;
    font-size: 9px;
    text-align: left;
    /*font-weight: 600;*/
    margin-right: 7px;
    color: #0c0c0c;
  }
}
